import './App.css';
import Header from "./components/header/header";
import PanelDemo from "./components/homePage/home";

function App() {
  return (
    <div className="App">
      <Header/>
      <h2 className='titulo'>Production: New Inventory</h2>
      <PanelDemo></PanelDemo>
    </div>
  );
}

export default App;
