
import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import "./home.css"

const PanelDemo = () => {
    const [workOrder, setWorkOrder] = useState('');
    const [orderQty, setOrderQty] = useState('');
    const [caseQty, setCaseQty] = useState('');
    const [selectWorkOrder, setSelectWorkOrder] = useState(null);
    const [site, setSite] = useState("Test");
    const [signed, setSigned] = useState("Mike");
    const [scanLocation, setScanLocation] = useState("Production");
    const [scanType, setScanType] = useState("New finished Product");
    const [hora, setHora] = useState('');

    const cities = [
        { name: 'Test_1', code: '1' },
        { name: 'Test_2', code: '2' },
        { name: 'Test_3', code: '3' },
        { name: 'Test_4', code: '4' },
    ];
    const onWorkOrderChange = (e) => {
        setSelectWorkOrder(e.value);
    }
    useEffect(() => {
        ObtenerHora()
    }, []);

    const ObtenerHora = () => {
        let hoy = new Date()
        let hora = hoy.toLocaleTimeString('en-US');
        setHora(hora)
    }

    return (
        <div className='paneles p-fluid'>
            <div className='col-4'>
                <Panel header="Enter New Production">
                    <div className='tituloInput'>Enter WorkOrder</div >
                    <InputText value={workOrder} onChange={(e) => setWorkOrder(e.target.value)} />
                    <div className='tituloInput mt-5'>Select WorkOrder Item</div>
                    <Dropdown value={selectWorkOrder} options={cities} onChange={onWorkOrderChange} optionLabel="name" placeholder="Select a City" />
                    <div className='col-12 flex flex-wrap p-0 justify-content-between mt-5'>
                        <div className='col-5 p-0'>
                            <div className='tituloInput'>Order Qty Remaining</div>
                            <InputText value={orderQty} onChange={(e) => setOrderQty(e.target.value)} disabled/>
                        </div>
                        <div className='col-5 p-0'>
                            <div className='tituloInput'>Case Qty</div>
                            <InputText value={caseQty} onChange={(e) => setCaseQty(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-6 mx-auto mt-5'>
                        <Button label="Submit New Product" className="p-button-success " />
                    </div>
                </Panel>
            </div>
            <div className='col-4'>
                <Panel header="Scanned Detail">
                    <h3 className='hora'>{hora}</h3>
                    <h3 className='hora'>New Product QRcode</h3>
                </Panel>
            </div>
            <div className='col-4'>
                <Panel header="Configuration">
                    <div className='tituloInput'>Site</div >
                    <InputText value={site} disabled className='color-input'/>
                    <div className='tituloInput mt-5'>Signed In As</div>
                    <InputText value={signed} disabled className='color-input'/>
                    <div className='tituloInput mt-5'>Scan Location</div>
                    <InputText value={scanLocation} disabled className='color-input'/>
                    <div className='tituloInput mt-5'>Scan Type</div>
                    <InputText value={scanType} disabled className='color-input'/>
                </Panel>
            </div>
        </div>
    )
}

export default PanelDemo;