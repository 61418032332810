import React, { useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';

const Header = () => {

    const [activeIndex, setActiveIndex] = useState(3);

    const items = [
        {label: 'Dashboards', icon: 'pi pi-fw pi-home'},
        {label: 'Productions', icon: 'pi pi-fw pi-angle-down'},
        {label: 'Materials', icon: 'pi pi-fw pi-angle-down'},
        {label: 'Inventory', icon: 'pi pi-fw pi-angle-down'},
        {label: 'Shipping', icon: 'pi pi-fw pi-angle-down'},
        {label: 'Management', icon: 'pi pi-fw pi-angle-down'}
    ];

    return (
        <div>
            <div className="card ">
                <TabMenu model={items} />
            </div>
        </div>
    );
}

export default Header;